<template lang="pug">
.template-chooser-advanced-tactics-recommendations(
  ref="block"
  data-track-property="eventName:admin_click|location:template-chooser|component:advanced-tactics|setting:{event}"
)
  .row.justify-content-between.align-items-center.mb-6
    .col
      om-heading.font-size-3(h3) {{ $t('onboarding.wizard.pages.recommendation.advanced.title') }}
    .col-auto
      om-link.text-nowrap(
        primary
        withIconRight
        @click="openUrlInBlank(tacticLibraryUrl)"
        data-track-property="change:showTacticsLibrary"
      ) {{ $t('templateChooser.advanedTactics.moreLink') }}
  .row
    FixedAdvancedTacticsRecommendation(:tactics="fixedTactics" isHorizontal)
      template(slot="advanced-tactics-recommendation-card" slot-scope="{ tactic }")
        AdvancedTacticCard(
          :index="tactic.index"
          :tactic="tactic.data"
          :learnMoreOpenUrl="false"
          @click="onCardClick"
        )
  portal(to="root")
    MoreDetails(
      :aiTypes="aiTypes"
      :about="about"
      :type="getOptimizationType(about)"
      @deselect="onDeselect"
    )
    Book(
      :about="about"
      @track="onTrack($event)"
      :type="getOptimizationType(about)"
      @close="openDetailsModal"
    )
    DcPersonalization(@close="onDeselect")
</template>

<script>
  import FixedAdvancedTacticsRecommendation from '@/components/Tactics/FixedAdvancedTacticsRecommendation.vue';
  import AdvancedTacticCard from '@/components/Tactics/AdvancedTacticCard.vue';

  import ssrMixin from '@/mixins/ssr';
  import { tacticLibraryUrl } from '@/utils/tactics';
  import commonFlowsMixin from '@/mixins/commonFlows';
  import homepageBlockMixin from '../../mixins/homepageBlock';
  import childRouteMixin from '../../mixins/childRoute';

  export default {
    name: 'AdvancedTacticsRecommendations',
    components: {
      FixedAdvancedTacticsRecommendation,
      AdvancedTacticCard,
      Skeleton: () =>
        import('@/components/TemplateChooser/components/skeletons/RecommendationSkeleton.vue'),
    },
    mixins: [ssrMixin, homepageBlockMixin, childRouteMixin, commonFlowsMixin],
    data: () => ({ advancedTacticsLimit: 3 }),
    computed: {
      tacticLibraryUrl() {
        return tacticLibraryUrl[this.$i18n.locale];
      },
      fixedTactics() {
        const locale = this.$i18n.locale;
        return [
          {
            name: {
              [locale]: this.$t('newCampaignFlow.websiteOptimization.optimize.abTest.title'),
            },
            usage: {
              [locale]: this.$t('newCampaignFlow.websiteOptimization.optimize.abTest.description'),
            },
            svgPreviews: [require('@/assets/admin/img/advanced-tactics/abTest_2024_v1.png')],
            type: 'abTest',
          },
          {
            name: {
              [locale]: this.$t('newCampaignFlow.websiteOptimization.optimize.sab.title'),
            },
            usage: {
              [locale]: this.$t('newCampaignFlow.websiteOptimization.optimize.sab.description'),
            },
            svgPreviews: [require('@/assets/admin/img/advanced-tactics/sab_2024_v1.png')],
            chip: {
              text: 'AI',
              color: 'gradient',
            },
            type: 'sab',
          },
          {
            name: {
              [locale]: this.$t('newCampaignFlow.websiteOptimization.optimize.sppo.title'),
            },
            usage: {
              [locale]: this.$t('newCampaignFlow.websiteOptimization.optimize.sppo.description'),
            },
            svgPreviews: [require('@/assets/admin/img/advanced-tactics/sppo_2024_v1.png')],
            chip: {
              text: 'AI',
              color: 'gradient',
            },
            type: 'sppo',
          },
        ];
      },
    },
    created() {
      this.$emit('loaded');
    },
    methods: {
      openUrlInBlank(url) {
        window.open(url, '_blank');
      },
      onCardClick(event) {
        this.about = event?.type;
        this.openDetailsModal();
      },
      onDeselect() {
        this.about = null;
      },
    },
  };
</script>
