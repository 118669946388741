<template lang="pug">
.advanced-tactics.fix-advanced-tactics
  .advanced-tactics-header
    slot(name="advanced-tactics-recommendation-header")

  .advanced-tactics-content
    slot(name="advanced-tactics-recommendation-content-before-cards")

    div(:class="innerWrapperClass")
      div(
        :class="cardWrapperClass"
        v-for="(tactic, index) in tactics"
        :key="`fixed-tactic-${index}`"
      )
        slot(name="advanced-tactics-recommendation-card" :tactic="{ index, data: tactic }")

    slot(name="advanced-tactics-recommendation-content-after-cards")
</template>

<script>
  import Slider from '@/components/Tactics/Slider.vue';

  export default {
    components: {
      Slider,
    },
    props: {
      tactics: {
        type: Array,
        required: true,
      },
      isHorizontal: {
        type: Boolean,
        default: false,
      },
      isVertical: {
        type: Boolean,
        default: false,
      },
      isSlider: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      cardWrapperClass() {
        return this.isSlider ? 'swiper-slide' : 'advanced-tactics-recommendation-card-wrapper';
      },
      innerWrapperClass() {
        if (this.isHorizontal) return 'advanced-tactics-cards-horizontal';
        if (this.isVertical) return 'advanced-tactics-cards-vertical';
        if (this.isHorizontal) return 'advanced-tactics-cards-slider';
        return 'advanced-tactics-cards';
      },
    },

    created() {
      this.$emit('loaded');
    },
  };
</script>

<style lang="sass">
  .advanced-tactics-cards-horizontal
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 35px

  .advanced-tactics-recommendation-card-wrapper
    display: contents
</style>
